@import 'variables';

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-top: 60px;
  background-color: $bliss-black;
  color: $bliss-gray;
}

.form-control {
  border-radius: 2rem;
}

.navbar {
  min-height: 4.5em;
  .navbar-nav {
    .nav-link {
      box-shadow: -2px -2px 2px 1px #42425a, 2px 2px 2px 1px #1f1f2f;
      border-radius: 2em;
      color: $bliss-gray;
      margin-right: 10px;
      padding: 0.7rem;
      &:hover {
        color: #fff;
      }
      &.active {
        box-shadow: inset 2px 2px 2px 1px #1f1f2f, inset -2px -2px 2px 1px #42425a;
        color: #fff;
      }
    }
    .nav-item {
      .dropdown-menu {
        background-image: linear-gradient(to right, #353545, #1d1d2c);
        border-radius: 1em;
        border-color: #1d1d2c;
        .dropdown-item {
          background-image: linear-gradient(to right, #353545, #1d1d2c);
          border-color: #1d1d2c;
          color: $bliss-gray;
          margin-right: 10px;
          padding: 0.7rem;
          &:hover {
            color: #fff;
          }
          &.active {
            color: #fff;
          }
        }
      }
    }
  }

  .nav-button {
    width: 10em;
    text-align: center;
    justify-content: center;
    margin-left: 0.5em;
  }

  .navbar-brand {
    height: 50px;
    width: 100px;
    padding: 0;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}

.footer {
  .navbar-nav {
    .nav-link {
      box-shadow: none;
      color: $bliss-gray;
      padding: 0.7rem;
      &:hover {
        color: #fff;
      }
      &.active {
        color: #fff;
      }
    }
  }
}
.full-height {
  height: calc(100vh - 120px) !important;
}
.carousel-home {
  margin: 0 -15px;
  width: auto;
}
.carousel-show {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}
.custom-data-table {
  min-height: 350px;
}
.btn {
  border-radius: 2em;
  @include transition(
    opacity 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease
  );

  &:hover,
  &:focus {
    filter: alpha(opacity = 80);
    opacity: 0.8;
  }
  &.btn-secondary {
    background-image: none;
    background-color: transparent;
    border-color: transparent;
    &:not(:disabled).active {
      border-color: $bliss-blue;
      margin-right: 10px;
      box-shadow: inset 2px 2px 2px 1px #1f1f2f, inset -2px -2px 2px 1px #42425a !important;
      color: #fff;
    }
    &.active {
      background-image: linear-gradient(to left, #353545, #1d1d2c);
      border-color: $bliss-blue;
      margin-right: 10px;
    }
  }
  &.btn-primary {
    background-image: linear-gradient(to left, #1c6e90, #1b9dd2);
    border-color: #1b9dd2;
    &:not(:disabled).active {
      border-color: $bliss-blue;
    }
    &.active {
      background-image: linear-gradient(to left, #353545, #1d1d2c);
      border-color: $bliss-blue;
    }
    &:hover {
      background-color: lighten(#fff, 15%);
    }
  }
  &.btn-warning {
    background-image: linear-gradient(to left, #b6490e, #f56211);
    border-color: #f56211;
    color: $white;
    font-size: 0.8em;
  }
  &.btn-success {
    background-image: linear-gradient(to left, #32b122, #1b6f10);
    border-color: #32b122;
    color: $white;
  }
  &.btn-info {
    background-image: linear-gradient(to left, #1ba3dc, #1e4788);
    border-color: #1ba3dc;
    color: $white;
  }
  &.btn-danger {
    background-image: linear-gradient(to left, #d72038, #7f212e);
    border-color: #d72038;
    color: $white;
  }
  &.btn-bliss {
    background-image: linear-gradient(to right, $bliss-pink, $bliss-sky-blue);
    color: #fff;
    border: 1px solid $bliss-sky-blue;
  }
  &.btn-dark {
    background-image: linear-gradient(to right, #2a2a3a, #353545);
    box-shadow: -2px -2px 5px 2px #42425a, 2px 2px 5px 3px #1f1f2f;
    border: 1px solid #353545;
    color: #737386;
  }
  &.btn-blue {
    background-color: $bliss-blue;
    color: $bliss-dark-gray;
    background-image: none;
    color: #fff;
    padding: 3px 0px;
    font-size: 0.7em;
    margin: 0px 4px;
    min-width: 70px;
    &.active {
      background-color: $bliss-sky-blue;
      color: #fff;
    }
  }
  .btn-link {
    background-image: none;
    background-color: none;
    border: none;
  }
}

// MARGINS
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.bg-dark {
  background-image: linear-gradient(to right, $bliss-blue, $bliss-dark-blue);
}
.text-small {
  font-size: 0.6em;
}
.text-green {
  color: $bliss-green;
  font-weight: 200;
}
.text-yellow {
  color: $bliss-yellow;
  font-weight: 200;
}
.text-gray {
  color: #737386;
}
.text-bliss {
  background: linear-gradient(to right, $bliss-pink, $bliss-sky-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-blue-gray {
  color: $bliss-gray;
  font-weight: 600;
  font-size: 0.8em;
}
.text-white {
  color: $white;
}
.bg-black {
  background-color: $bliss-black;
}
.text-pink {
  color: #e83e8c;
}
.text-slim {
  font-weight: 200;
}
.bliss-select {
  background-color: #1d1d2c;
  color: #fff;
  z-index: 600;
}

.bliss-card-solid-bg {
  @extend .base-bliss-card;
  background-color: #2d2d3d;
}

.bliss-card {
  @extend .base-bliss-card;
  background-image: linear-gradient(to right, #353545, #1d1d2c);
}

.bliss-card-bg {
  background-image: linear-gradient(to right, #353545, #1d1d2c);
}

.bliss-btn-bg {
  background-image: linear-gradient(to left, #1c6e90, #1b9dd2);
}

.base-bliss-card {
  border-radius: 1em;
  padding: 0.5em;
  height: 100%;
  p {
    margin: 3px;
  }
  .title {
    font-weight: 600;
    font-size: 1.3em;
  }
  .subtitle {
    font-weight: 100;
    font-size: 1.3em;
  }
  .rate {
    color: #fff;
    font-size: 3em;
    font-weight: 100;
  }
  &.single {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.modal {
  &-content {
    background-image: linear-gradient(to right, $bliss-blue, $bliss-dark-blue);
    border-radius: 10px;
    border-color: $bliss-gray;
  }
  &-header,
  &-footer,
  &-body {
    background-color: transparent;
    color: $white;
    border-color: $bliss-gray;
  }
  &-header > button > span {
    color: white;
  }
}

.padding-fix {
  padding-left: 0;
  @media (max-width: 600px) {
    padding-left: 15px;
  }
}

.bliss-card-v-0 {
  @extend .bliss-card-v;
  background-color: #181826;
}

.bliss-card-v-1 {
  @extend .bliss-card-v;
  background-color: #252536;
}

.bliss-card-v-2 {
  @extend .bliss-card-v;
  background-color: #3e3e4f;
}

.bliss-card-v {
  border-radius: 1em;
  padding: 1em;
  p {
    margin: 3px;
  }
  .title {
    font-weight: 600;
  }
  .rate {
    color: #fff;
    font-size: 2em;
  }
}
.blue-gradient {
  background-image: linear-gradient(to right, #353545, #1d1d2c);
  .title {
    font-weight: 600;
    color: #fff;
    font-size: 0.8em;
    padding-top: 1em;
  }
  &-inverse {
    background-image: linear-gradient(to left, #353545, #1d1d2c);
    .title {
      font-weight: 600;
      color: #fff;
      font-size: 0.8em;
      padding-top: 1em;
    }
  }
}
.rounded-corners {
  border-radius: 1em;
  &-top {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }
  &-bottom {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
}

.text-blue {
  color: #1ba5de;
}
.text-orange {
  color: $orange;
}
.divider {
  background-color: black;
}
.wale-popover {
  background-color: $bliss-black;
  color: $white;
  border-radius: 1rem;
  padding: 1rem;
  min-width: 280px;
  .title {
    font-size: 0.8em;
    font-weight: 500;
    color: #fff;
  }
  p {
    font-size: 0.8em;
    margin-bottom: 1em;
  }
  .item-name {
    color: $bliss-gray;
  }
  .item-desc {
    color: $bliss-sky-blue;
  }
}

.round-btn {
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.9em;
  margin-right: 10px;
}
.btn-circle {
  box-shadow: 1px 1px 1px 1px #333;
  border-radius: 50%;
  color: $bliss-gray;
  margin-right: 10px;
  padding: 0px;
  height: 30px;
  width: 30px;
  color: #fff;
  background-color: #d1548e98;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bs-popover-right .arrow:after {
  border-right-color: $bliss-black;
}
.filling-bar {
  box-shadow: -1px -1px 1px 1px #42425a, 2px 2px 2px 1px #1f1f2f;
  border-radius: 0.5em;
  color: $bliss-gray;
  padding: 0.5rem;
  width: 46px;
  text-align: center;
  .bar {
    height: 8px;
    width: 30px;
    margin-top: 2px;
    border-radius: 1px;
    &-full {
      @extend .bar;
      background-color: #fff;
    }
    &-empty {
      @extend .bar;
      background-color: $bliss-dark-blue;
    }
  }
}
.dash {
  background-color: $bliss-sky-blue;
  height: 3px;
}
.dotted-dash {
  background-color: $bliss-pink;
  height: 3px;
}
.text-medium {
  font-size: 0.8em;
}
.lane {
  height: 100%;
  .text-small.text-white {
    font-weight: 800;
  }
  .text-pink {
    font-weight: 200;
  }
  .text-blue {
    font-weight: 200;
  }
}
.vertical-center-line {
  height: 7px;
  width: 100%;
  border-top: 1px solid $bliss-gray;
}
.btn-shadow {
  box-shadow: -2px -2px 2px 1px #42425a, 2px 2px 2px 1px #1f1f2f;
  border-radius: 1.5em;
  color: $bliss-gray;
  margin-right: 10px;
  padding: 3px 6px 3px 6px;
  background-color: $bliss-blue;
  @media (max-width: 600px) {
    margin-right: 5px;
  }
}

.overlay {
  &-fifth {
    z-index: 120;
  }
  &-fourth {
    z-index: 90;
  }
  &-third {
    z-index: 60;
  }
  &-second {
    z-index: 30;
  }
  &-first {
    z-index: 10;
  }
}
.date-nav {
  position: absolute;
  color: #fff;
  font-size: 0.8em;
  background-image: linear-gradient(to left, #353545, #1d1d2c);
  border-radius: 1em;
  padding: 1em;
  z-index: 90;
  height: 90%;
  p {
    margin: 3px;
    color: #fff;
  }
  p.text-error {
    color: $bliss-red;
  }
  input {
    &:active,
    &:hover,
    &:focus {
      border: none;
    }
  }
}

.text-error {
  color: $bliss-red;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-input {
  &,
  &:hover,
  &:focus,
  &:active {
    padding: 0;
    border-radius: 0;
    color: #eee;
    background-color: transparent;
    border: 0 none transparent;
    border-bottom: 1px solid $bliss-black;
    outline: none;
    outline-offset: 0;
    box-shadow: none;
    transition: 0.2s;
    font-weight: 200;
  }
  &:focus {
    border-bottom: 1px solid $bliss-sky-blue;
  }
}

input[type='time'] {
  color: #9696a0;
}

.border-bottom-light {
  border-bottom: 1px solid $bliss-dark-gray;
}

.bliss-input {
  background: transparent;
  border: none;
  color: $bliss-gray;
  padding: 0;
  font-weight: 200;
  border-radius: 0;
  &:disabled,
  &:disabled:hover {
    background: transparent;
    color: $bliss-gray;
  }
  &:hover,
  &:focus {
    border: none;
    color: #fff;
    background: transparent;
  }
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.blissway {
  background: -webkit-linear-gradient(left, $bliss-red 35%, $bliss-pink 10%, $bliss-sky-blue 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.image-round {
  max-height: 150px;
  max-width: 150px;
  border-radius: 50%;
}

.Toastify__toast {
  height: 80px;
  width: 320px;
  border-radius: 10px;
}
.Toastify__toast--default {
  background: #fff;
  color: #363646;
}
.Toastify__toast--info {
  background: #fff;
  color: #363646;
}
.Toastify__toast--success {
  background: #fff;
  color: #363646;
}
.Toastify__toast--warning {
  background: #fff;
  color: #363646;
}
.Toastify__toast--error {
  background: #fff;
  color: #363646;
}

.mobile {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
  @media only screen and (device-width: 768px) {
    display: block;
  }
}
.desktop {
  @media (max-width: 600px) {
    display: none;
  }
}
.container-disabled {
  opacity: 0.5;
}
.container-fluid {
  padding: 15px;
}
.shift-left {
  margin-left: 1em;
}
.shift-left-small {
  margin-left: -2px !important;
}
.buffer-bottom {
  margin-bottom: 1rem !important;
}
.buffer-top {
  margin-top: 1rem !important;
}
.buffer-top-on-resize {
  @media (min-width: 1647px) {
    margin-top: 1em !important;
  }
}
.hide {
  visibility: hidden;
}

.fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.monitor-row {
  justify-content: space-evenly;
}

.center-row-columns th {
  vertical-align: middle;
}

.pagination-elem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
}

.page-number {
  border: 1px solid white;
  padding: 0.3em 1em;
  border-radius: 0.5em;
  font-weight: bold;
}

.bliss-input-field,
.datetime .rdtPicker,
.datetime .form-control {
  background-color: $bliss-blue;
  color: rgb(216, 216, 216);
  border-radius: 4px;
  border: 1px solid $bliss-dark-gray;

  &::placeholder {
    color: $bliss-dark-gray;
  }
}


.datetime {
  .rdtPicker {
    .rdtYears,
    .rdtMonths,
    .rdtDays {
      [class^='rdt']:hover {
        background-color: #565666;
      }
    }
    .rdtSwitch:hover,
    .rdtBtn:hover {
      background-color: #565666;
    }
  }
}

.state-select {
  min-width: 11em;
}

.state-select .form-group {
  margin-bottom: 0;
}

.wale-events-options .form-group {
  margin-bottom: 0;
  min-width: 20em;
  font-size: 0.9em;
}
.rows-select .form-group {
  margin-bottom: 0;
  margin-left: 1em;
  min-width: 100px;
}

.navbar-select .form-group {
  margin-bottom: 0;
  margin-left: 1em;
  margin-right: 1em;
  min-width: 12em;
}

.margin-top-tokens {
  margin-top: 2em;
}

.break-words {
  overflow-wrap: break-word;
}

.margin-left-tokens {
  margin-left: 2em;
}

.bliss-input-common-style {
  min-height: 38px;
  color: hsl(0, 0%, 70%);
}

.tokens-overscroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.carousel-button {
  padding-left: 1em;
  padding-right: 1em;
  margin: 0;
}

.carousel-button-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.carousel-button-right {
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.carousel-button-play-stop {
  border-radius: 0;
}

.bliss-input-set-width {
  width: 10em;
}

.history-toggle {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.active-toggle {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.wale-event-container {
  display: flex;
  align-items: flex-end;
}

.maintenance-icons-container {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  margin-left: 10px;
  position: absolute;
}

.maintenance-icon {
  filter: invert(55%) sepia(100%) saturate(2612%) hue-rotate(333deg) brightness(102%) contrast(101%)
    drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  width: 50px;
}

.maintenance-icon-color {
  filter: invert(55%) sepia(100%) saturate(2612%) hue-rotate(333deg) brightness(102%) contrast(101%);
}
