@import 'variables';

.feed {
  text-align: right;
  color: $bliss-green;
  font-size: 0.8em;
  margin-right: 1.2em;
  font-weight: 600;
  align-self: center;
}

.old-feed {
  color: $bliss-yellow !important;
}

.live-feed {
  border-radius: 50%;
  background: linear-gradient(to right, $bliss-green, $bliss-dark-green);
  border: 1px solid $bliss-green;
  height: 14px;
  width: 14px;
  display: inline-block;
}

.old-live-feed {
  border-radius: 50%;
  background: linear-gradient(to right, $bliss-yellow, $bliss-dark-yellow);
  border: 1px solid $bliss-yellow;
  height: 14px;
  width: 14px;
  display: inline-block;
}

.outside-hours-feed {
  text-align: right;
  color: $bliss-gray;
  font-size: 0.8em;
  margin-right: 1.2em;
  font-weight: 600;
  padding-top: 0.5rem;
}
.outside-hours {
  border-radius: 50%;
  background: linear-gradient(to right, $bliss-pink, $bliss-red);
  border: 1px solid $bliss-pink;
  height: 14px;
  width: 14px;
  display: inline-block;
}

.roadside-map-wrapper {
  display: relative;
  width: 100%;
  .fixed-wales {
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 3%;
    z-index: 400;
    @media (max-width: 600px) {
      top: 70%;
    }
  }
  .map-btns {
    position: absolute;
    justify-content: space-between;
    display: flex;
    z-index: 300;
    width: 98%;
    .eastbound {
      color: $white;
      margin-left: 20px;
      @media (max-width: 600px) {
        display: none;
      }
    }
    h5 {
      color: $white;
      margin-right: 1.2em;
      margin-left: 1.5em;
      display: inline;
      @media (max-width: 600px) {
        margin-left: 1em;
        margin-right: 1em;
      }
    }
    .btn-blue {
      z-index: 200;
    }
    @media (max-width: 600px) {
      .btn:nth-child(4) {
        display: none;
      }
      .btn:nth-child(5) {
        display: none;
      }
    }
  }
}

.wale-avg {
  margin: auto;
  background-color: $bliss-black;
  color: $white;
  border-radius: 1rem;
  padding: 1.8em;
  max-width: 25em;
  font-size: 0.8em;
  text-align: left;
  margin-top: 30px;
  .title {
    font-size: 1.4em;
    font-weight: 500;
    color: $white;
    text-align: right;
  }
  p {
    margin-bottom: 1em;
  }
  .item-name {
    color: $bliss-gray;
  }
  .item-desc {
    color: $bliss-sky-blue;
  }
  @media (max-width: 600px) {
    width: 300px;
    height: auto;
    margin-top: 100px;
  }
  @media only screen and (device-width: 768px) {
    width: 450px;
    height: auto;
    margin-top: 150px;
    margin-left: 150px;
  }
}

.event-img {
  width: 90%;
  border-radius: 5%;
  border: 4px solid #fff;
}

.event-img.from-video {
  border: 4px solid $bliss-sky-blue;
}

.wale-img {
  max-width: 45em;
  height: auto;
  border-radius: 5%;
  border: 2px solid #fff;
  @media only screen and (device-width: 768px) {
    display: none;
  }
  @media (max-width: 600px) {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
  }
}

.paused {
  border: 2px solid rgb(164, 10, 10);
}

.make-model {
  position: relative;
  text-align: center;
  margin: -15px auto;
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: $white;
  background-size: contain;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: 1600px) {
    top: 84%;
  }
  &.desc {
    top: 74%;
    font-size: 0.75em;
    font-weight: 300;
    text-transform: uppercase;
    @media (min-width: 1600px) {
      top: 79%;
    }
  }
}

.wale-title {
  width: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: $white;
  background-size: contain;
}

.wale-name {
  text-align: left;
}

.captured-at {
  font-size: 0.9em;
  font-weight: 400;
  text-align: right;
}

.wale-table-label {
  text-align: right;
  font-size: 0.5 em;
  width: 40%;
  font-weight: 300;
  color: $white;
  text-transform: uppercase;
}

.wale-table-title {
  text-align: center;
  width: 100%;
  font-size: 1em;
  font-weight: 600;
  color: $white;
}

.wale-table-data {
  text-align: left;
  width: 40%;
  font-size: 1em;
  font-weight: 600;
  color: $white;
}

.plate {
  position: relative;
  text-align: center;
  margin-top: -15px;
  color: $bliss-black;
  background-size: contain;
  text-transform: uppercase;
  .plate-img {
    height: 70px;
  }
  .plate-number {
    position: absolute;
    font-size: 1.8em;
    font-weight: 600;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.us-nm {
      color: $bliss-red;
    }
    &.us-ar {
      color: #284447;
    }
    &.us-ca {
      color: #0016a3;
    }
    &.us-ut {
      color: #000d4b;
    }
  }
  .plate-state {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.8em;
    font-weight: 100;
  }
  @media (max-width: 600px) {
    display: none;
  }
  @media only screen and (device-width: 768px) {
    display: none;
  }
}

.accuracy {
  margin-top: -16px;
  .vertical-divider {
    border-right: 1px solid $bliss-black;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (device-width: 768px) {
    display: none;
  }
}

.pop-up {
  background-image: linear-gradient(to right, #353545, #1d1d2c);
  border: 1px solid white;
  border-radius: 1em;
  padding: 1em 2em;
  position: absolute;
  z-index: 20;
}

.expand-pop-up {
  width: 150%;
  right: 0;
  bottom: 0;
}

.zone-selector {
  position: relative;
  text-align: center;
  top: -8em;
  height: 22em;
  color: white;
  font-weight: bold;
  border-right: 3px dashed rgba(255, 255, 255, 0.8);
  padding: 0 !important;
}

.zone-selector:first-child {
  border-left: 3px dashed rgba(255, 255, 255, 0.8);
}

.zone-selector:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.4);

  .zone-head {
    background-color: rgba(78, 162, 217, 1);
    border: 1px solid white;
  }
}

.zone-selector-row {
  width: 77.5%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10em;
  right: 12em;
  z-index: 400;
  margin-top: 18%;
}

.zone-head {
  background-color: rgba(78, 162, 217, 0.6);
  border-radius: 1em;
  padding: 0.2em 0;
  position: relative;
  top: -0.7em;
  border: 1px solid rgba(78, 162, 217, 0.6);
}

#zone1 {
  flex-grow: 48 !important;
}

#zone2 {
  flex-grow: 32 !important;
}

#zone3 {
  flex-grow: 25 !important;
}

.highway-name {
  background-color: rgba(54, 54, 70, 0.8);
  padding: 0.6em 0.8em;
  border-radius: 0.7em;
  margin-left: 3em;
}

.trip-image {
  max-width: 100%;
  display: block;
}

.border-ooh {
  border-color: #666666;
}

.ooh-status {
  color: #666666;
}
.warning-status {
  padding: 0em 0.3em;
  border: 2px solid gold;
  color: gold;
  border-radius: 0.5em;
  animation: blinker 0.7s linear infinite;
}

.danger-status {
  @extend .warning-status;
  border: 2px solid $bliss-red;
  color: $bliss-red;
}

@keyframes blinker {
  50% {
    opacity: 0.35;
  }
}

@media screen and (max-width: 600px) {
  #map-image {
    object-fit: contain;
  }
  .zone-selector-row {
    top: 40%;
    left: 4em;
  }
  #zone1 span,
  #zone2 span,
  #zone3 span {
    display: none;
  }
  .zone-head {
    font-size: 1em;
  }
  .zone-selector {
    height: 25vh;
  }
}

.wale-motion,
.wale-plate {
  color: $bliss-sky-blue;
}

.no-blue-link a {
  all: unset;
}

// Need to repeat the class name to give it more specifity than the Bootstrap's default
.metrics-table.metrics-table thead th,
.metrics-table tbody td {
  text-align: center;
  vertical-align: middle;
}
