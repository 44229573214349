// BLISS COLORS
$bliss-green: #32b122;
$bliss-dark-green: #1b6f10;
$bliss-sky-blue: #1ba3dc;
$bliss-ocean: #1e4788;
$bliss-blue: #363646;
$bliss-dark-blue: #1c1c2b;
$bliss-black: #16161b;
$bliss-pink: #e21e80;
$bliss-purple: #5e3769;
$bliss-gray: #9696a0;
$bliss-dark-gray: #737386;
$white: #fff;
$bliss-red: #d72038;
$bliss-dark-red: #9f2b4f;
$bliss-yellow: #ded024;
$bliss-dark-yellow: #92881f;
$orange: #f16111;
