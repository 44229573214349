.cars {
  font-size: 0.9em;
  .car-title {
    color: #fff;
  }
  .car-name {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .car-number {
    display: inline-block;
    width: 1.3em;
    text-align: center;
    color: #737386;
    margin-right: 5px;
  }
  .car-percentage {
    color: #1ba3dc;
  }
}
.mobile-fix {
  @media (max-width: 600px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.gap-4 {
  gap: 1.5em;
}
