.login-bg {
  background-image: linear-gradient(to right, #353545 5%, #2a2a3a 30%, #363646 2%, #1c1c2b 100%);
  @media (max-width: 600px) {
    background-image: linear-gradient(to right, #353545 5%, #2a2a3a 30%);
  }
}
.login {
  position: relative;
  padding-top: 10em;
  @media (max-width: 600px) {
    padding-top: 3em;
  }
  .form-group {
    .form-label {
      font-weight: 600;
      font-size: 0.8em;
    }
    &i {
      position: absolute;
    }
    .form-control {
      background-color: transparent;
      border: none;
      color: #ddd;
      border-bottom: 1px solid black;
      border-radius: 0;
      font-weight: 200;
      &:focus {
        border: none;
        border-bottom: 1px solid black;
        background-color: transparent;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: none;
      border-bottom: 1px solid black;
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: none;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.browser-alert-container {
  background-color: rgba(11, 11, 28, 0.7);
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.bliss-alert {
  display: flex;
  .content {
    margin-left: 15px;
    font-size: 0.7em;
    .title {
      font-weight: 600;
    }
    p {
      margin: 0;
    }
    display: inline-block;
  }
  .icon {
    padding: 12px 5px 12px 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    text-align: center;
    &.error {
      color: #ffd0d1;
      background-color: #ffd0d1;
    }
    &.warning {
      color: #fffcd0;
      background-color: #fffcd0;
    }
    &.info {
      color: #1ba3dc;
      background-color: #bce9fc;
    }
    &.success {
      color: #32b122;
      background-color: #cefdc8;
    }
  }
}

.offline {
  display: flex;
  background-color: #f7f6f5;
  color: #434357;
  border-radius: 10px;
  padding: 5px 5px;
  height: 50px;
  p {
    margin: 0;
    line-height: 1.1;
  }
  .content {
    padding: 5px;
    .title {
      color: #434357;
      font-weight: 600;
      font-size: 1em;
    }
    color: #5b5a6f;
    font-size: 0.8em;
  }
  .icon {
    padding-left: 5px;
    color: #1ba3dc;
    width: 50px;
    background-color: #bce9fc;
    border-radius: 5px;
  }
}

.browser-alert {
  position: fixed;
  bottom: 30px;
  display: flex;
  align-items: center;
  height: 100px;
  width: 400px;
  border-radius: 10px;
  padding: 15px;
  background-color: #fff;
  color: #434357;
  .content {
    margin-left: 15px;
    font-size: 0.7em;
    .title {
      font-weight: 600;
    }
    p {
      margin: 0;
    }
    display: inline-block;
  }
  .icon {
    padding: 15px 5px 15px 5px;
    height: 80px;
    color: #fffcd0;
    background-color: #fffcd0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    text-align: center;
    &.warning {
      color: #fffcd0;
      background-color: #fffcd0;
    }
    &.info {
      color: #1ba3dc;
      background-color: #bce9fc;
    }
  }
  .btn-link {
    float: right;
    font-size: 1em;
    padding: 0;
    margin: 0;
    &:hover {
      text-decoration: none;
      color: #32b122;
    }
  }
}
